import styled from "@emotion/styled";
import { Info as InfoIcon } from "@mui/icons-material";
import { Chip } from "@mui/material";
import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams
} from "@mui/x-data-grid";
import { useQuery } from "react-query";

import {
  deletePromotion,
  getAllBookingCodesWithPromotions
} from "../../clients/services/sams/promotion";
import {
  OfferStatus,
  PromotionBookingOfferData
} from "../../clients/services/sams/promotion/types";
import { SunProduct } from "../../clients/services/sams/types";
import { dateTimeFull } from "../../constants/dateFormat";
import { useAppSelector } from "../../state/hooks";
import TableComponent from "../TableComponent";
import { Rows } from "../TableComponent/types";
import { dateSortComparator } from "../utils/dateSortComparator";

const UploadCodesLargeRequestMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  background-color: #e5f6fd;
  color: #014361;
  font-weight: 700;
  font-size: 12px;
  margin: 20px 32px 0 0;
  padding: 0 2px;
  border-radius: 4px;
  border: 1px solid #eaeaea;
  text-align: center;
`;

const BookingCodesTable = () => {
  const { isLoading, data, error, refetch } = useQuery(
    ["getAllBookingCodesWithPromotions"],
    () => getAllBookingCodesWithPromotions()
  );
  const { showUploadCodesLargeRequestMessage } = useAppSelector(
    (state) => state.notification
  );

  const rows: Rows | undefined = data?.map(
    (offer: PromotionBookingOfferData) => {
      return {
        id: offer.offerCode,
        offerName: offer.offerName,
        sunProduct: offer.sunProduct || "",
        codeCount: offer.codeCount || "N/A",
        codesRemaining: offer.codesRemaining || "N/A",
        offerStatus: offer.status,
        createdDate: offer.lastUpdatedDate
          ? dateTimeFull(offer.lastUpdatedDate)
          : "N/A",
        status: offer.publicationStatus,
        bookingCodeButtons: {
          upload: true,
          delete: offer.codesRemaining !== 0
        }
      };
    }
  );

  const tableProperties = {
    itemName: "offerName",
    actionLabel: "Offer"
  };

  const getSunProductChip = (params: GridRenderCellParams<any, SunProduct>) => {
    switch (params.value) {
      case SunProduct.SUN_SAVERS:
        return (
          <Chip
            color="secondary"
            label="Savers"
            variant="filled"
            data-testid="sunProductTestIDSunSavers"
          />
        );
      case SunProduct.SUN_MOBILE:
        return (
          <Chip
            color="primary"
            label="Mobile"
            variant="filled"
            data-testid="sunProductTestIDSunMobile"
          />
        );
      case SunProduct.MEMBERS_ENCLOSURE:
        return (
          <Chip
            color="info"
            label="Members Enclosure"
            variant="filled"
            data-testid="sunProductTestIDMembersEnclosure"
          />
        );
      case SunProduct.SUN_CLUB:
        return (
          <Chip
            label="Sun Club"
            variant="filled"
            data-testid="sunProductTestIDSunClub"
            style={{ backgroundColor: "#F44336", color: "#fff" }}
          />
        );
      default:
        return <></>;
    }
  };

  const columns: GridColDef[] = [
    {
      field: "offerName",
      headerName: "Offer name",
      minWidth: 170,
      flex: 1
    },
    {
      field: "sunProduct",
      headerName: "Used By",
      minWidth: 90,
      maxWidth: 120,
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, SunProduct>) =>
        getSunProductChip(params)
    },
    {
      field: "codeCount",
      headerName: "Code count",
      minWidth: 90,
      flex: 1
    },
    {
      field: "codesRemaining",
      headerName: "Codes remaining",
      minWidth: 130,
      flex: 1
    },
    {
      field: "createdDate",
      headerName: "Last Updated",
      minWidth: 200,
      flex: 1,
      sortComparator: dateSortComparator
    },
    {
      field: "offerStatus",
      headerName: "Offer Status",
      valueGetter: (params: GridValueGetterParams) => ({
        offerStatus: params.row.offerStatus,
        status: params.row.status
      }),
      minWidth: 130,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        switch (params.value.offerStatus) {
          case OfferStatus.FUTURE:
            return <Chip color="secondary" label="Future" variant="outlined" />;
          case OfferStatus.ACTIVE:
            return <Chip color="success" label="Active" variant="outlined" />;
          case OfferStatus.EXPIRED:
            return <Chip color="error" label="Expired" variant="outlined" />;
          default:
            return "";
        }
      }
    }
  ];

  return (
    <>
      {showUploadCodesLargeRequestMessage && (
        <UploadCodesLargeRequestMessage data-testid="upload-codes-large-request-message">
          <InfoIcon />
          <p>
            The booking codes file is very large and it's going to take some
            time to upload. Please refresh the page to see the updated booking
            code count. In case of duplicated codes, they will be ignored.
          </p>
        </UploadCodesLargeRequestMessage>
      )}
      <TableComponent<PromotionBookingOfferData>
        isLoading={isLoading}
        error={error}
        refetch={refetch}
        rows={rows as Rows}
        columns={columns}
        initialState={{
          sorting: {
            sortModel: [
              {
                field: "offerStatus",
                sort: "asc"
              }
            ]
          }
        }}
        testId="BookingCodesTableTestID"
        tableProperties={tableProperties}
        deleteFunction={deletePromotion}
      />
    </>
  );
};

export default BookingCodesTable;
