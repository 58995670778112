import { CreateMorePageRequestBody } from "../../clients/services/sams/content/morePage";
import { MorePageType, Status } from "../../clients/services/sams/types";

export const formDataTemplate: CreateMorePageRequestBody = {
  type: MorePageType.INTERNAL_PAGE,
  headline: "",
  shortDescription: "",
  image: "",
  pageTitle: "",
  content: "",
  slug: "",
  status: Status.DRAFT,
  url: ""
};
