import * as yup from "yup";

import { Status, SunProduct } from "../../clients/services/sams/types";

const validationSchema = yup.object({
  title: yup.string().required("Title is required"),
  text: yup.string().required("Message is required"),
  readMoreLabel: yup.string(),
  dismissLabel: yup.string(),
  readMoreURL: yup.string(),
  startDate: yup.date().required("Start Date is required"),
  sunProduct: yup.mixed().oneOf(Object.values(SunProduct)),
  endDate: yup
    .date()
    .min(yup.ref("startDate"), "End date can't be before start date")
    .required("End Date is required"),
  page: yup
    .string()
    .required("Page is required")
    .test({
      name: "same",
      exclusive: false,
      params: {},
      message: "Page should exist inside Members Enclosure",
      test(value) {
        return !(
          this.parent.sunProduct === SunProduct.MEMBERS_ENCLOSURE &&
          (value === "/cashout" ||
            value === "/raffle" ||
            value === "/offer" ||
            value === "/home")
        );
      }
    }),
  status: yup
    .mixed()
    .oneOf(Object.values(Status))
    .required("Status is required")
});

export default validationSchema;
