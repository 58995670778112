import { Chip } from "@mui/material";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useQuery } from "react-query";

import {
  deleteArticle,
  listArticles
} from "../../clients/services/sams/content/article";
import { ArticleListResponse } from "../../clients/services/sams/content/article/types";
import { getLatestHistory } from "../../clients/services/sams/content/contentHistory";
import { PostType, SunProduct } from "../../clients/services/sams/types";
import { dateTimeFull, dateTimeZone } from "../../constants/dateFormat";
import { findLatestHistory } from "../../constants/findLatestHistory";
import TableComponent from "../TableComponent";
import { Rows } from "../TableComponent/types";
import { dateSortComparator } from "../utils/dateSortComparator";

const ArticleTable = () => {
  const { isLoading, data, error, refetch } = useQuery(["listArticle"], () =>
    listArticles()
  );
  const { isLoading: isLoadingHistory, data: historyData } = useQuery(
    ["getLatestHistory"],
    () => getLatestHistory(PostType.ARTICLE)
  );

  const getSunProductChip = (params: GridRenderCellParams<any, SunProduct>) => {
    switch (params.value) {
      case SunProduct.SUN_SAVERS:
        return (
          <Chip
            color="secondary"
            label="Savers"
            variant="filled"
            data-testid="sunProductTestIDSunSavers"
          />
        );
      case SunProduct.SUN_MOBILE:
        return (
          <Chip
            color="primary"
            label="Mobile"
            variant="filled"
            data-testid="sunProductTestIDSunMobile"
          />
        );
      case SunProduct.MEMBERS_ENCLOSURE:
        return (
          <Chip
            color="info"
            label="Members Enclosure"
            variant="filled"
            data-testid="sunProductTestIDMembersEnclosure"
          />
        );
      case SunProduct.SUN_CLUB:
        return (
          <Chip
            label="Sun Club"
            variant="filled"
            data-testid="sunProductTestIDSunClub"
            style={{ backgroundColor: "#F44336", color: "#fff" }}
          />
        );
      default:
        return <></>;
    }
  };

  const rows: Rows | undefined = data?.map((article: ArticleListResponse) => {
    return {
      id: article.id,
      shortHeadline: article.shortHeadline,
      sunProduct: article.sunProduct,
      status: article.status,
      publishStartDate: dateTimeZone(article.publishStartDate),
      publishEndDate: dateTimeZone(article.publishEndDate),
      user: findLatestHistory(historyData, article.id)?.user || "Unknown",
      createdDate: findLatestHistory(historyData, article.id)
        ? dateTimeFull(findLatestHistory(historyData, article.id)?.createdDate)
        : "Created from service"
    };
  });
  const tableProperties = {
    itemName: "article",
    actionLabel: "Article"
  };

  const columns: GridColDef[] = [
    { field: "shortHeadline", headerName: "Short Headline", flex: 1 },
    {
      field: "sunProduct",
      headerName: "Used By",
      minWidth: 90,
      maxWidth: 120,
      flex: 1,
      renderCell: (params: GridRenderCellParams<any, SunProduct>) =>
        getSunProductChip(params)
    },
    { field: "publishStartDate", headerName: "Start Date", flex: 1 },
    { field: "publishEndDate", headerName: "End Date", flex: 1 },
    {
      field: "user",
      headerName: "Author",
      minWidth: 90,
      flex: 1
    },
    {
      field: "createdDate",
      headerName: "Last Modified",
      minWidth: 180,
      flex: 1,
      sortComparator: dateSortComparator
    }
  ];

  return (
    <TableComponent<ArticleListResponse>
      isLoading={isLoading || isLoadingHistory}
      error={error}
      refetch={refetch}
      rows={rows as Rows}
      columns={columns}
      testId="ArticleTableTestID"
      createButton={{
        link: "/create-article",
        text: "Create Article"
      }}
      tableProperties={tableProperties}
      deleteFunction={deleteArticle}
    />
  );
};

export default ArticleTable;
