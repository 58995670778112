import { Status, SunProduct } from "../types";

export interface RegisteredPromotion {
  code: string;
  cpn: string;
  promotionCode: string;
  runningTotal: RunningTotal;
  hasRedeemed: boolean;
  isEligibleToRedeem: boolean;
}

interface RunningTotal {
  balance: number;
  redemptionThreshold: number;
}

export interface Promotion {
  actions: string[];
  dnaActions: string[];
  allowMultipleRedemption: boolean;
  code: string;
  codeCollectEndDate: string;
  codeCollectStartDate: string;
  featuredImage: string;
  featuredImageSmall: string;
  headline: string;
  maximumRedemptions?: number;
  name: string;
  offerContent: string;
  optInButtonText: string;
  previewCpns: string[];
  promotionType: PromotionType;
  positionNumber: number;
  publicationStatus: Status;
  redemptionButtonText: string;
  redemptionEndDateTime: string;
  bookingCodeEndDate?: string;
  redemptionCount: number;
  redemptionLinkUrl: string;
  redemptionStartDateTime?: string;
  redemptionThreshold: number;
  resendButtonText: string;
  sell: string;
  shortHeadline: string;
  slug: string;
  feeOnRedemption: number;
  sunProduct: SunProduct;
  termsAndConditions: string;
  status?: OfferStatus;
  redemptionAllocation?: RedemptionAllocation;
  soldOut?: boolean;
  showMarketingPermissions?: boolean;
  partnerName?: string;
  privacyPolicyUrl?: string;
  privacyPolicy?: string;
  alwaysOn?: boolean;
  redemptionMechanic?: OfferRedemptionMechanic;
}

export interface PromotionRedemption {
  code: string;
  cpn: string;
  promotionCode: string;
  createdDatetime: string;
}

export interface RegisteredPromotionInfo {
  optedIn: boolean;
  codesCollected?: number;
  hasRedeemed?: boolean;
  isEligibleToRedeem?: boolean;
  redeemedDateTime?: string;
}

export type PromotionFullInfo = Promotion &
  RegisteredPromotionInfo & {
    cpn?: string;
  };

interface RedemptionAllocation {
  total: number;
  remaining: number;
}

export enum OfferStatus {
  FUTURE = "Future",
  ACTIVE = "Active",
  EXPIRED = "Expired"
}

export enum OfferRedemptionMechanic {
  INTERNAL = "INTERNAL",
  EXTERNAL = "EXTERNAL",
  PRODUCT = "PRODUCT",
  BOOKING_CODE = "BOOKING_CODE"
}

export const mapMechanic = new Map([
  [OfferRedemptionMechanic.INTERNAL, "API_JOURNEY"],
  [OfferRedemptionMechanic.EXTERNAL, "WEB_JOURNEY"],
  [OfferRedemptionMechanic.PRODUCT, "MERLIN_JOURNEY"],
  [OfferRedemptionMechanic.BOOKING_CODE, "UNIQUE_BOOKING_CODE_JOURNEY"]
]);

export enum PromotionType {
  COMPETITION = "COMPETITION",
  NONCODE = "NONCODE",
  REGULAR = "REGULAR",
  DONATION = "DONATION",
  WALLET = "WALLET"
}

export enum MobilePromotionType {
  COMPETITION = "COMPETITION",
  NONCODE = "NONCODE"
}

export interface OffersPositionsData {
  code: string;
  positionNumber: number;
}

export interface MarketingPermissionData {
  firstName: string;
  lastName: string;
  email: string;
}

export const mapSunProduct = new Map<SunProduct, string>([
  [SunProduct.SUN_SAVERS, "Sun Savers"],
  [SunProduct.SUN_MOBILE, "Sun Mobile"],
  [SunProduct.MEMBERS_ENCLOSURE, "Members Enclosure"],
  [SunProduct.SUN_CLUB, "Sun Club"]
]);

export interface PromotionBookingOfferData {
  offerName: string;
  offerCode: string;
  codeCount: number;
  sunProduct: SunProduct;
  lastUpdatedDate: string;
  codesRemaining: number;
  publicationStatus: Status;
  status: string;
  unsuccessfullyUploadedBCodesCount: number;
}

export interface PromotionBookingOfferDataResponse {
  data: PromotionBookingOfferData;
  headers: Record<string, string>;
}

export interface GetPromotionsParams {
  codes?: string;
  filterExpired?: boolean;
}

export interface Attraction {
  id: number;
  attractionName: string;
}
export interface BookingCodeSummary {
  uploadDate: string;
  totalCodeCount: number;
  availableCodeCount: number;
}
