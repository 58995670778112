import * as yup from "yup";

import { MorePageType, Status } from "../../clients/services/sams/types";
import { containsWhitespace } from "../Article/validationSchema";

export const validationSchema = yup.object({
  type: yup
    .mixed()
    .oneOf(Object.values(MorePageType))
    .required("Type is required"),
  headline: yup.string().required("Headline is required"),
  shortDescription: yup.string().required("Short Description is required"),
  image: yup.string().when("type", {
    is: (value: MorePageType) => value === MorePageType.INTERNAL_PAGE,
    then: (schema) => schema.required("Image is required")
  }),
  pageTitle: yup.string().when("type", {
    is: (value: MorePageType) => value === MorePageType.INTERNAL_PAGE,
    then: (schema) => schema.required("Page Title is required")
  }),
  content: yup.string().when("type", {
    is: (value: MorePageType) => value === MorePageType.INTERNAL_PAGE,
    then: (schema) => schema.required("Content is required")
  }),
  slug: yup.string().when("type", {
    is: (value: MorePageType) => value === MorePageType.INTERNAL_PAGE,
    then: (schema) =>
      schema
        .required("Slug is required")
        .test(
          "This field cannot contain white space",
          "This field cannot contain white space",
          (value) => !containsWhitespace(value as string)
        )
  }),
  url: yup
    .string()
    .url("Must be valid URL")
    .nullable()
    .when("type", {
      is: (value: MorePageType) => value === MorePageType.EXTERNAL_LINK,
      then: (schema) => schema.required("URL is required")
    }),
  status: yup
    .mixed()
    .oneOf(Object.values(Status))
    .required("Status is required")
});
