import styled from "@emotion/styled";
import { TextField, TextareaAutosize, Grid, Typography } from "@mui/material";
import { AxiosError } from "axios";
import { FormikProps, FormikProvider, useFormik } from "formik";
import { FC, useEffect, useState } from "react";
import { useMutation, UseMutationResult } from "react-query";

import { ImageResponse } from "../../../clients/services/sams/gallery/types";
import {
  getRedemptionUrl,
  testRedemptionEmail
} from "../../../clients/services/sams/gfm";
import {
  RedemptionUrl,
  TestRedemptionEmailProps,
  TestRedemptionUrlProps
} from "../../../clients/services/sams/gfm/types";
import {
  OfferRedemptionMechanic,
  PromotionType
} from "../../../clients/services/sams/promotion/types";
import { getStretchRedemptionUrl } from "../../../clients/services/sams/stretch";
import { TestStretchRedemptionUrlProps } from "../../../clients/services/sams/stretch/types";
import { SunProduct } from "../../../clients/services/sams/types";
import { imageResizeMessages } from "../../../constants/imageResizeMessages";
import useMutationHandler from "../../../hooks/useMutationHandler";
import { promotion } from "../../../util/constants";
import { ErrorMessage } from "../../ErrorMessage";
import HistoryTable from "../../HistoryTable";
import ImageSelection from "../../ImageSelection";
import StatusSelector from "../../StatusSelector";
import PromotionTypeSelect from "../PromotionTypeSelect";
import SunProductSelect from "../SunProductSelect";
import SwitchSection from "../SwitchSection";
import TabsMenu from "../TabPanels";
import { FormDataInterface } from "../types";

const Container = styled.div`
  padding-top: 15px;
`;

const InputField = styled(TextField)`
  margin-top: 8px;
`;

const StyledTextArea = styled(TextareaAutosize)`
  min-height: 100px;
`;

interface FormProps {
  values: FormDataInterface;
  validationSchema: any;
  onSubmit: (values: FormDataInterface) => void;
  isSubmitting: boolean;
  isUpdateOperation: boolean;
  formId: string;
  title: string;
  actionLabel: string;
  actionTestId: string;
}

const Form: FC<FormProps> = ({
  values,
  validationSchema,
  onSubmit,
  formId,
  title,
  actionLabel,
  actionTestId,
  isSubmitting,
  isUpdateOperation
}) => {
  const [value, setValue] = useState(0);

  const formik: FormikProps<FormDataInterface> = useFormik({
    initialValues: values,
    validationSchema,
    onSubmit
  });

  const [testStretchUrlErrorMessage, setStretchUrlErrorMessage] = useState("");
  const [testGfmUrlErrorMessage, setTestGfmUrlErrorMessage] = useState("");
  const [onTestRedemptionEmailSuccess, onTestRedemptionEmailError] =
    useMutationHandler(`Redemption email successfully tested!`);
  const [onGetRedemptionUrlSuccess, onGetRedemptionUrlError] =
    useMutationHandler(`Redemption url is valid.`);

  const testRedemptionEmailMutation: UseMutationResult<
    any,
    AxiosError<any>,
    TestRedemptionEmailProps,
    unknown
  > = useMutation<any, AxiosError<any>, TestRedemptionEmailProps>(
    testRedemptionEmail,
    {
      onSuccess: onTestRedemptionEmailSuccess,
      onError: (error) => {
        onTestRedemptionEmailError(error);
        setStretchUrlErrorMessage(
          error.response?.data.errorMessage || error.message
        );
      }
    }
  );

  const getGFMRedemptionUrlMutation: UseMutationResult<
    any,
    AxiosError<any>,
    TestRedemptionUrlProps,
    unknown
  > = useMutation<any, AxiosError<any>, TestRedemptionUrlProps>(
    getRedemptionUrl,
    {
      onSuccess: (data: RedemptionUrl) => {
        onGetRedemptionUrlSuccess();
        window.open(data.redemptionUrl, "_blank");
      },
      onError: (error) => {
        onGetRedemptionUrlError(error);
        setTestGfmUrlErrorMessage(
          error.response?.data.errorMessage || error.message
        );
      }
    }
  );

  const getStretchRedemptionUrlMutation: UseMutationResult<
    any,
    AxiosError<any>,
    TestStretchRedemptionUrlProps,
    unknown
  > = useMutation<any, AxiosError<any>, TestStretchRedemptionUrlProps>(
    getStretchRedemptionUrl,
    {
      onSuccess: (data: RedemptionUrl) => {
        onGetRedemptionUrlSuccess();
        window.open(data.redemptionUrl, "_blank");
      },
      onError: (error) => {
        onGetRedemptionUrlError(error);
        setStretchUrlErrorMessage(
          error.response?.data.errorMessage || error.message
        );
      }
    }
  );

  useEffect(() => {
    if (
      formik.values.sunProduct === SunProduct.SUN_MOBILE ||
      formik.values.sunProduct === SunProduct.SUN_CLUB
    ) {
      formik.values.promotionType =
        formik.values.promotionType !== PromotionType.NONCODE
          ? PromotionType.COMPETITION
          : formik.values.promotionType;

      if (formik.values.sunProduct === SunProduct.SUN_MOBILE) {
        formik.values.redemptionMechanic =
          formik.values.promotionType === PromotionType.COMPETITION
            ? OfferRedemptionMechanic.INTERNAL
            : OfferRedemptionMechanic.EXTERNAL;
      }

      formik.setFieldValue("resendButtonText", "");
    }
  }, [formik.values.sunProduct, formik.values.promotionType]);

  return (
    <Container data-testid={`${formId}TestID`}>
      <Typography variant="h4">{title}</Typography>
      <FormikProvider value={formik}>
        <form id={formId} onSubmit={formik.handleSubmit}>
          {formik.submitCount !== 0 && (
            <ErrorMessage formValidationErrors={formik.errors} />
          )}
          <Grid container>
            <Grid container item xs={8}>
              <InputField
                fullWidth
                id="name"
                name="name"
                label="Name"
                variant="standard"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              <ImageSelection
                id="image"
                value={formik.values.image}
                imageResizeMessage={imageResizeMessages.offer}
                error={formik.touched.image && Boolean(formik.errors.image)}
                helperText={formik.touched.image && formik.errors.image}
                btnText={
                  formik.values.image.length !== 0
                    ? "Select another image"
                    : "Select an image"
                }
                setFieldValue={formik.setFieldValue}
                isImageSelectionDisabled={!formik.values.code}
                isImageSelected={formik.values.image.length !== 0}
                postType="promotion"
                imageToUpload={formik.values.image}
                postHeadline={formik.values.code}
                setImageFieldsValues={(data: ImageResponse) => {
                  if ("imageSmall" in data) {
                    formik.setFieldValue("image", data.image);
                    formik.setFieldValue("imageSmall", data.imageSmall);
                  }
                }}
              />
              <SunProductSelect
                formik={formik}
                sunProductSelect={promotion.sunProductSelect}
              />
              <SwitchSection
                formik={formik}
                description="Choose whether this offer could be reviewed internally by specific group of CPNs prior to going live."
                name="internalReview"
                label="Preview internally"
                sectionTitle="Internal Review"
                toggleInnerComponent={formik.values.internalReview}
                innerComponent={
                  <StyledTextArea
                    id="previewCpns"
                    name="previewCpns"
                    placeholder="Enter CPNs of users (separated by commas) which should be eligible to preview this offer prior to going live."
                    value={formik.values.previewCpns}
                    onChange={formik.handleChange}
                    maxRows={4}
                  />
                }
              />
              <PromotionTypeSelect formik={formik} />
              <TabsMenu
                formik={formik}
                value={value}
                isUpdateOperation={isUpdateOperation}
                testGfmUrlErrorMessage={testGfmUrlErrorMessage}
                testStretchUrlErrorMessage={testStretchUrlErrorMessage}
                isLoadingTestRedemptionEmailMutation={
                  testRedemptionEmailMutation.isLoading
                }
                isLoadingGetRedemptionUrlMutation={
                  getGFMRedemptionUrlMutation.isLoading ||
                  getStretchRedemptionUrlMutation.isLoading
                }
                setTestGfmUrlErrorMessage={setStretchUrlErrorMessage}
                setTestStretchUrlErrorMessage={setStretchUrlErrorMessage}
                testRedemptionEmailMutation={testRedemptionEmailMutation}
                getGFMRedemptionUrlMutation={getGFMRedemptionUrlMutation}
                getStretchRedemptionUrlMutation={
                  getStretchRedemptionUrlMutation
                }
                setValue={setValue}
              />
            </Grid>
            <Grid container item xs={4} style={{ maxHeight: "600px" }}>
              <StatusSelector
                isSubmitting={isSubmitting}
                form={formik}
                actionLabel={actionLabel}
                fieldName={"publicationStatus"}
                testId={actionTestId}
                showPreviewStatus={true}
              />
              {values.code ? (
                <HistoryTable postType={"promotion"} postId={values.code} />
              ) : null}
            </Grid>
          </Grid>
        </form>
      </FormikProvider>
    </Container>
  );
};

export default Form;
