import { AxiosResponse } from "axios";

import staticConfig from "../../../../config/static";
import client from "../../base";
import { SunProduct } from "../types";

import {
  GetRedemptionUrl,
  RedemptionUrl,
  TestRedemptionEmail,
  TestRedemptionEmailProps,
  TestRedemptionEmailResponse,
  TestRedemptionUrlProps
} from "./types";
import { getPromotionUrl } from "./utils";

const baseUrl = staticConfig.servicesUrls?.samsService;

export const getRedemptionUrl: GetRedemptionUrl = async ({
  cpn,
  redemptionUrl,
  promotionSlug,
  sunProduct = SunProduct.SUN_SAVERS
}: TestRedemptionUrlProps) => {
  const promotionUrl = getPromotionUrl(sunProduct, promotionSlug);

  const encodedRedemptionUrl = encodeURIComponent(
    encodeURIComponent(redemptionUrl)
  );
  const encodedPromotionUrl = encodeURIComponent(promotionUrl);

  const url = `${baseUrl}/gfm/customers/${cpn}/promotion-redemptions/get-redemption-url?redemptionUrl=${encodedRedemptionUrl}&promotionUrl=${encodedPromotionUrl}`;

  const response: AxiosResponse<RedemptionUrl> = await client.get(url);
  return response.data;
};

export const testRedemptionEmail: TestRedemptionEmail = async ({
  cpn,
  redemptionUrl,
  email
}: TestRedemptionEmailProps) => {
  const url = `${baseUrl}/gfm/customers/${cpn}/promotion-redemptions/test-redemption-email`;

  const response: AxiosResponse<TestRedemptionEmailResponse> =
    await client.post(url, {
      redemptionUrl,
      email
    });
  return response.data;
};
