import styled from "@emotion/styled";
import { Alert, Button, Typography } from "@mui/material";
import { Box } from "@mui/system";

import { TestType } from "../../Form/utils";
import { InputField } from "../commonStyledComponents";

const StyledBox = styled(Box)`
  position: relative;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 16px;
  margin-top: 24px;
`;

const StyledAlert = styled(Alert)`
  margin-top: 10px;
`;

const StyledButton = styled(Button)`
  max-width: 200px;
  margin-top: 10px;
`;

interface Props {
  type: TestType;
  cpnInputValue?: string;
  cpnInputOnChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  emailOrXcodeInputValue?: string;
  emailOrXcodeInputOnChange: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void;
  testUrlErrorMsg: string;
  testButtonOnClick: () => void;
  testButtonDisabled: boolean;
}

const RedemptionUrlTester = ({
  type,
  cpnInputValue,
  cpnInputOnChange,
  emailOrXcodeInputValue,
  emailOrXcodeInputOnChange,
  testButtonOnClick,
  testButtonDisabled,
  testUrlErrorMsg
}: Props) => {
  const getBoxTitle = (): string => `${type} URL test`;
  const getButtonLabel = (): string => `Test ${type} Url`;

  return (
    <StyledBox>
      <Typography
        position={"absolute"}
        top={"-21px"}
        bgcolor={"#f7f7f7"}
        p={1}
        variant="body1"
      >
        {getBoxTitle()}
      </Typography>
      <InputField
        fullWidth
        id={`testCpn${type === TestType.GFM ? "Gfm" : "Stretch"}`}
        name={`testCpn${type === TestType.GFM ? "Gfm" : "Stretch"}`}
        label="Test CPN"
        variant="standard"
        value={cpnInputValue}
        onChange={cpnInputOnChange}
      />
      <InputField
        fullWidth
        id={`test${type === TestType.GFM ? "Email" : "XCODE"}`}
        name={`test${type === TestType.GFM ? "Email" : "XCODE"}`}
        label={`Test ${type === TestType.GFM ? "Email" : "XCode"}`}
        variant="standard"
        value={emailOrXcodeInputValue}
        onChange={emailOrXcodeInputOnChange}
      />
      {!!testUrlErrorMsg && (
        <StyledAlert severity="error">{testUrlErrorMsg}</StyledAlert>
      )}
      <StyledButton
        disabled={testButtonDisabled}
        variant="contained"
        color="primary"
        onClick={testButtonOnClick}
      >
        {getButtonLabel()}
      </StyledButton>
    </StyledBox>
  );
};

export default RedemptionUrlTester;
