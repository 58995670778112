import { store } from "../../../../state";
import { isPreProdEnvironment } from "../../../../util/environment";
import { SunProduct } from "../types";

export const getPromotionUrl = (sunProduct: SunProduct, slug: string) => {
  const { config } = store.getState();
  const isPreProd = isPreProdEnvironment(config?.environment ?? "");

  const sunSaversUrl = `https://savers.${isPreProd ? "uat-" : ""}thesun.co.uk`;
  const sunClubUrl = `https://club.${isPreProd ? "uat-" : ""}thesun.co.uk`;

  return `${
    sunProduct === SunProduct.SUN_CLUB ? sunClubUrl : sunSaversUrl
  }/offers/${slug}`;
};
