import { Tab, Tabs } from "@mui/material";
import { FormikProps } from "formik";

import {
  OfferRedemptionMechanic,
  PromotionType
} from "../../../clients/services/sams/promotion/types";
import { SunProduct } from "../../../clients/services/sams/types";
import { FormDataInterface } from "../types";

import AttractionInfoPanel from "./AttractionInfoPanel";
import ContentPanel from "./ContentPanel";
import InfoPanel from "./InfoPanel";
import MarketingPermissionsPanel from "./MarketingPermissionsPanel";
import RedemptionSettingsPanel from "./RedemptionSettingsPanel";
import { useConditions } from "./RedemptionSettingsPanel/hooks";
import StatusesPanel from "./StatusesPanel";

interface Props {
  value: number;
  formik: FormikProps<FormDataInterface>;
  isUpdateOperation: boolean;
  testGfmUrlErrorMessage: string;
  testStretchUrlErrorMessage: string;
  isLoadingTestRedemptionEmailMutation: boolean;
  isLoadingGetRedemptionUrlMutation: boolean;
  setTestGfmUrlErrorMessage: (newValue: string) => void;
  setTestStretchUrlErrorMessage: (newValue: string) => void;
  testRedemptionEmailMutation: any;
  getGFMRedemptionUrlMutation: any;
  getStretchRedemptionUrlMutation: any;
  setValue: (newValue: number) => void;
}

const TabsMenu: React.FC<Props> = ({
  value,
  formik,
  isUpdateOperation,
  testGfmUrlErrorMessage,
  testStretchUrlErrorMessage,
  isLoadingTestRedemptionEmailMutation,
  isLoadingGetRedemptionUrlMutation,
  setTestGfmUrlErrorMessage,
  setTestStretchUrlErrorMessage,
  testRedemptionEmailMutation,
  getGFMRedemptionUrlMutation,
  getStretchRedemptionUrlMutation,
  setValue
}) => {
  const { isNonCodePromotionWithHubOrClub } = useConditions(formik.values);
  const testGFMRedemptionHandler = async (
    redemptionMechanic: OfferRedemptionMechanic,
    cpn: string,
    redemptionUrl: string,
    email: string,
    promotionSlug: string
  ) => {
    setTestGfmUrlErrorMessage("");
    await (redemptionMechanic === OfferRedemptionMechanic.INTERNAL
      ? testRedemptionEmailMutation.mutate({
          cpn,
          redemptionUrl,
          email
        })
      : getGFMRedemptionUrlMutation.mutate({
          cpn,
          redemptionUrl,
          promotionSlug,
          sunProduct: formik.values.sunProduct
        }));
  };

  const testStretchRedemptionHandler = async (
    redemptionUrl: string,
    cpn: string,
    xcode: string
  ) => {
    setTestStretchUrlErrorMessage("");
    await getStretchRedemptionUrlMutation.mutate({
      cpn,
      redemptionUrl,
      xcode
    });
  };

  const handleTabChange = (_: any, newValue: number) => {
    setValue(newValue);
  };
  /* eslint-disable no-param-reassign */
  const changeMarketingPermissionFields = (tabIndex?: number) => {
    formik.values.showMarketingPermissions = false;
    formik.values.partnerName = "";
    formik.values.privacyPolicyUrl = "";
    if (tabIndex && tabIndex === 4 && !isNonCodePromotionWithHubOrClub) {
      return setValue(0);
    }
  };

  return (
    <>
      <Tabs
        value={value}
        onChange={handleTabChange}
        aria-label="Offer Sections"
      >
        <Tab label="Info" data-testid={"InfoTab"} value={0} />
        <Tab
          label="Redemption Settings"
          data-testid={"SettingsTab"}
          value={1}
        />
        <Tab label="Copy" data-testid={"CopyTab"} value={2} />
        <Tab
          label="Offer Statuses"
          data-testid={"OfferStatusesTab"}
          value={3}
        />
        {formik.values.promotionType !== PromotionType.REGULAR &&
        formik.values.promotionType !== PromotionType.COMPETITION &&
        formik.values.sunProduct !== SunProduct.SUN_CLUB ? (
          changeMarketingPermissionFields(value)
        ) : (
          <Tab
            label="Marketing Permissions"
            data-testid={"MarketingPermissionsTab"}
            value={4}
          />
        )}
        {formik.values.redemptionMechanic === "BOOKING_CODE" && (
          <Tab
            label="Attraction info"
            data-testid={"AttractionInfoTab"}
            value={5}
          />
        )}
      </Tabs>
      <InfoPanel
        formik={formik}
        value={value}
        isUpdateOperation={isUpdateOperation}
      />
      <RedemptionSettingsPanel
        formik={formik}
        value={value}
        testGfmUrlErrorMessage={testGfmUrlErrorMessage}
        testStretchUrlErrorMessage={testStretchUrlErrorMessage}
        testGFMRedemptionHandler={testGFMRedemptionHandler}
        testStretchRedemptionHandler={testStretchRedemptionHandler}
        isLoadingTestRedemptionEmailMutation={
          isLoadingTestRedemptionEmailMutation
        }
        isLoadingGetRedemptionUrlMutation={isLoadingGetRedemptionUrlMutation}
        setTestGfmUrlErrorMessage={setTestGfmUrlErrorMessage}
        setTestStretchUrlErrorMessage={setTestStretchUrlErrorMessage}
      />
      <ContentPanel formik={formik} value={value} />
      <StatusesPanel formik={formik} value={value} />
      <MarketingPermissionsPanel formik={formik} value={value} />
      <AttractionInfoPanel formik={formik} value={value} />
    </>
  );
};

export default TabsMenu;
