import styled from "@emotion/styled";
import {
  Button,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  Typography
} from "@mui/material";
import React, { FC, useState, useEffect } from "react";
import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd";

import { ArticleListResponse } from "../../../clients/services/sams/content/article/types";
import { PartnershipListResponse } from "../../../clients/services/sams/content/partnership/types";
import { ProductListResponse } from "../../../clients/services/sams/merlin/types";
import { Promotion } from "../../../clients/services/sams/promotion/types";
import { SunProduct } from "../../../clients/services/sams/types";
import ConfirmationDialog from "../../ConfirmationDialog";
import OfferTableCell from "../OfferTableCell";
import { useChangeArticlePosition } from "../hooks/useChangeArticlePosition";
import { useChangePartnershipPosition } from "../hooks/useChangePartnershipPosition";
import { useChangePromotionPosition } from "../hooks/useChangePromotionPosition";

const Wrapper = styled.div`
  padding: 32px 0;
  padding-right: 32px;
`;
const TableContainer = styled(Table)`
  margin-top: 24px;
`;
const StyledButton = styled(Button)`
  margin-left: 32px;
`;
const HeadlineTableCell = styled(TableCell)`
  min-width: 250px;
`;
const TypeTableCell = styled(TableCell)`
  width: 420px;
`;
const TopStatusBar = styled.div`
  margin-top: 24px;
  display: flex;
`;
const transformOffers = (sortedData: Promotion[]) => {
  return sortedData.map((offer) => {
    return { code: offer.code, positionNumber: offer.positionNumber };
  });
};

const transformPartnerships = (sortedData: PartnershipListResponse[]) => {
  return sortedData.map((partnership) => {
    return { id: partnership.id, positionNumber: partnership.positionNumber };
  });
};

const transformArticles = (sortedData: ArticleListResponse[]) => {
  return sortedData.map((article) => {
    return { id: article.id, positionNumber: article.positionNumber };
  });
};

const checkIfOfferHasProduct = (
  products: ProductListResponse[],
  code: string
) => {
  return products.some((el) => {
    return el.xcode === code;
  });
};
interface OrderingTableInterface {
  fetchedData: (Promotion | ArticleListResponse | PartnershipListResponse)[];
  products: ProductListResponse[];
}

const OrderingTable: FC<OrderingTableInterface> = ({ fetchedData }) => {
  const [sunProduct, setSunProduct] = useState(SunProduct.SUN_SAVERS);
  const [sortedOffers, setSortedOffers] = useState<
    (Promotion | ArticleListResponse | PartnershipListResponse)[]
  >([]);
  const [hasChanges, setHasChanges] = useState(false);
  const promotionOrderingMutation = useChangePromotionPosition();
  const partnershipOrderingMutation = useChangePartnershipPosition();
  const articleOrderingMutation = useChangeArticlePosition();

  useEffect(() => {
    setSortedOffers(
      fetchedData.filter((offer) => offer.sunProduct === sunProduct)
    );
  }, [sunProduct, fetchedData]);

  const handleDragEnd = (e: DropResult) => {
    if (!e.destination || e.destination.index === e.source.index) return;
    const tempOffers = Array.from(sortedOffers);

    const [sourceOffer] = tempOffers.splice(e.source.index, 1);
    tempOffers.splice(e.destination.index, 0, sourceOffer);

    const updatedOffers = tempOffers.map((offer, index) => ({
      ...offer,
      positionNumber: index
    }));

    setHasChanges(true);
    setSortedOffers(updatedOffers);
  };

  const handleTabChange = (
    _: React.SyntheticEvent,
    newSunProduct: SunProduct
  ) => {
    setHasChanges(false);
    setSunProduct(newSunProduct);
  };

  const handleSaveChanges = () => {
    promotionOrderingMutation.mutate(
      transformOffers(
        sortedOffers.filter((entity) =>
          Object.hasOwn(entity, "promotionType")
        ) as Promotion[]
      )
    );

    partnershipOrderingMutation.mutate(
      transformPartnerships(
        sortedOffers.filter((entity) =>
          Object.hasOwn(entity, "partnershipText")
        ) as PartnershipListResponse[]
      )
    );

    articleOrderingMutation.mutate(
      transformArticles(
        sortedOffers.filter(
          (entity) =>
            Object.hasOwn(entity, "slug") &&
            !Object.hasOwn(entity, "partnershipText") &&
            !Object.hasOwn(entity, "code")
        ) as ArticleListResponse[]
      )
    );

    setHasChanges(false);
  };

  const getSunProductText = () => {
    switch (sunProduct) {
      case SunProduct.SUN_MOBILE:
        return "Sun Mobile";
      case SunProduct.SUN_SAVERS:
        return "Sun Savers";
      case SunProduct.MEMBERS_ENCLOSURE:
        return "Members Enclosure";
      case SunProduct.SUN_CLUB:
        return "Sun Club";
      default:
        return "";
    }
  };

  return (
    <Wrapper>
      <Typography variant="h4">{`${getSunProductText()} Offers and Articles Order`}</Typography>
      <TopStatusBar>
        <Tabs value={sunProduct} onChange={handleTabChange}>
          <Tab
            data-testid="SunSaversTabTestID"
            label="Sun Savers"
            value={SunProduct.SUN_SAVERS}
          />
          <Tab
            data-testid="SunMobileTabTestID"
            label="Sun Mobile"
            value={SunProduct.SUN_MOBILE}
          />
          <Tab
            data-testid="MembersEnclosureTestID"
            label="Members Enclosure"
            value={SunProduct.MEMBERS_ENCLOSURE}
          />
          <Tab
            data-testid="SunClubTestID"
            label="Sun Club"
            value={SunProduct.SUN_CLUB}
          />
        </Tabs>
        <ConfirmationDialog
          title={`Save Changes`}
          description={`Are you sure you would like to save the order of this list?`}
          actionLabel={`Save changes`}
          action={handleSaveChanges}
        >
          {(handleDialogOpen) => (
            <StyledButton
              color="primary"
              size="medium"
              variant="contained"
              disabled={!hasChanges}
              onClick={handleDialogOpen}
              data-testid={"SaveButtonTestId"}
            >
              Save Changes
            </StyledButton>
          )}
        </ConfirmationDialog>
      </TopStatusBar>

      <TableContainer>
        <DragDropContext onDragEnd={handleDragEnd}>
          <TableHead>
            <TableRow>
              <TableCell />
              <HeadlineTableCell>Headline</HeadlineTableCell>
              <TypeTableCell>Type</TypeTableCell>
            </TableRow>
          </TableHead>
          <Droppable droppableId="droppable-1">
            {(provider: any) => (
              <TableBody ref={provider.innerRef} {...provider.droppableProps}>
                {sortedOffers?.map((entity: any, index) => (
                  <OfferTableCell
                    key={`${index}_${entity.slug ? entity.slug : entity.url}`}
                    entity={entity}
                    index={index}
                    isMerlin={false}
                  />
                ))}
                {provider.placeholder}
              </TableBody>
            )}
          </Droppable>
        </DragDropContext>
      </TableContainer>
    </Wrapper>
  );
};

export default OrderingTable;
