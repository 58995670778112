import styled from "@emotion/styled";
import {
  TextField,
  FormLabel,
  FormControlLabel,
  Switch,
  FormControl,
  RadioGroup,
  Radio
} from "@mui/material";
import { FormikProps } from "formik";

import {
  mapMechanic,
  OfferRedemptionMechanic,
  PromotionType
} from "../../../../clients/services/sams/promotion/types";
import TabPanel from "../../../TabPanel";
import { isTestUrlButtonDisabled, TestType } from "../../Form/utils";
import { FormDataInterface } from "../../types";
import { Paragraph, StyledFormGroup } from "../commonStyledComponents";

import RedemptionUrlTester from "./RedemptionUrlTester";
import { useConditions } from "./hooks";

const InputField = styled(TextField)`
  margin-top: 8px;
`;

const DateInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DateInputField = styled(TextField)`
  margin-top: 16px;
  width: 47%;
`;

const StyledFormControl = styled(FormControl)`
  margin-top: 16px;
  width: 100%;
`;

interface Props {
  formik: FormikProps<FormDataInterface>;
  value: number;
  testGfmUrlErrorMessage: string;
  testStretchUrlErrorMessage: string;
  testGFMRedemptionHandler: (
    redemptionMechanic: OfferRedemptionMechanic,
    cpn: string,
    redemptionUrl: string,
    email: string,
    promotionSlug: string
  ) => void;
  testStretchRedemptionHandler: (
    redemptionUrl: string,
    cpn: string,
    xcode: string
  ) => void;
  isLoadingTestRedemptionEmailMutation: boolean;
  isLoadingGetRedemptionUrlMutation: boolean;
  setTestGfmUrlErrorMessage: (newValue: string) => void;
  setTestStretchUrlErrorMessage: (newValue: string) => void;
}

const RedemptionSettingsPanel: React.FC<Props> = ({
  formik,
  value,
  testGfmUrlErrorMessage,
  testStretchUrlErrorMessage,
  testGFMRedemptionHandler,
  testStretchRedemptionHandler,
  isLoadingTestRedemptionEmailMutation,
  isLoadingGetRedemptionUrlMutation,
  setTestGfmUrlErrorMessage,
  setTestStretchUrlErrorMessage
}) => {
  const {
    showCodeCollectStartDate,
    showRedemptionStartDateTime,
    showBookingCodeEndDate,
    showRedemptionButtonText,
    showResendButtonText,
    showRedemptionMechanic,
    showMaximumRedemptions,
    showFeeOnRedemption,
    showTransactionHistoryLabel,
    showRedemptionThreshold,
    shouldIncludeMechanic
  } = useConditions(formik.values);

  return (
    <TabPanel value={value} index={1}>
      {formik.values.promotionType === PromotionType.NONCODE && (
        <StyledFormGroup>
          <FormLabel component="legend">Always On</FormLabel>
          <Paragraph>Select whether this promotion is always on.</Paragraph>
          <FormControlLabel
            style={{ pointerEvents: "none" }}
            control={
              <Switch
                name="alwaysOn"
                style={{ pointerEvents: "auto" }}
                checked={formik.values.alwaysOn}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            }
            label="Always On"
          />
        </StyledFormGroup>
      )}
      {showCodeCollectStartDate && (
        <DateInputContainer>
          <DateInputField
            id="codeCollectStartDate"
            name="codeCollectStartDate"
            label="Code Collect Start Date"
            type="date"
            variant="standard"
            value={formik.values.codeCollectStartDate}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.codeCollectStartDate &&
              Boolean(formik.errors.codeCollectStartDate)
            }
            helperText={
              formik.touched.codeCollectStartDate &&
              formik.errors.codeCollectStartDate
            }
            InputLabelProps={{
              shrink: true
            }}
          />
          <DateInputField
            id="codeCollectEndDate"
            name="codeCollectEndDate"
            label="Code Collect End Date"
            type="date"
            variant="standard"
            value={formik.values.codeCollectEndDate}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.codeCollectEndDate &&
              Boolean(formik.errors.codeCollectEndDate)
            }
            helperText={
              formik.touched.codeCollectEndDate &&
              formik.errors.codeCollectEndDate
            }
            InputLabelProps={{
              shrink: true
            }}
          />
        </DateInputContainer>
      )}
      <DateInputContainer>
        {showRedemptionStartDateTime && (
          <DateInputField
            id="redemptionStartDateTime"
            name="redemptionStartDateTime"
            label="Redemption Start Date"
            type="datetime-local"
            variant="standard"
            value={formik.values.redemptionStartDateTime}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.redemptionStartDateTime &&
              Boolean(formik.errors.redemptionStartDateTime)
            }
            helperText={
              formik.touched.redemptionStartDateTime &&
              formik.errors.redemptionStartDateTime
            }
            InputLabelProps={{
              shrink: true
            }}
          />
        )}
        {!formik.values.alwaysOn && (
          <DateInputField
            id="redemptionEndDateTime"
            name="redemptionEndDateTime"
            label="Redemption End Date"
            type="datetime-local"
            variant="standard"
            value={formik.values.redemptionEndDateTime}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.redemptionEndDateTime &&
              Boolean(formik.errors.redemptionEndDateTime)
            }
            helperText={
              formik.touched.redemptionEndDateTime &&
              formik.errors.redemptionEndDateTime
            }
            InputLabelProps={{
              shrink: true
            }}
          />
        )}
      </DateInputContainer>
      {showBookingCodeEndDate && (
        <DateInputField
          id="bookingCodeEndDate"
          name="bookingCodeEndDate"
          label="Unique Booking Code End Date"
          type="datetime-local"
          variant="standard"
          value={formik.values.bookingCodeEndDate}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.bookingCodeEndDate &&
            Boolean(formik.errors.bookingCodeEndDate)
          }
          helperText={
            formik.touched.bookingCodeEndDate &&
            formik.errors.bookingCodeEndDate
          }
          InputLabelProps={{
            shrink: true
          }}
        />
      )}
      {formik.values.promotionType !== PromotionType.NONCODE && (
        <InputField
          fullWidth
          id="optInButtonText"
          name="optInButtonText"
          label="Opt In Button Text"
          variant="standard"
          value={formik.values.optInButtonText}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.optInButtonText &&
            Boolean(formik.errors.optInButtonText)
          }
          helperText={
            formik.touched.optInButtonText && formik.errors.optInButtonText
          }
        />
      )}
      {showRedemptionButtonText && (
        <InputField
          fullWidth
          id="redemptionButtonText"
          name="redemptionButtonText"
          label="Redemption Button Text"
          variant="standard"
          value={formik.values.redemptionButtonText}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.redemptionButtonText &&
            Boolean(formik.errors.redemptionButtonText)
          }
          helperText={
            formik.touched.redemptionButtonText &&
            formik.errors.redemptionButtonText
          }
        />
      )}
      {showResendButtonText && (
        <InputField
          fullWidth
          id="resendButtonText"
          name="resendButtonText"
          label="Resend Button Text"
          variant="standard"
          value={formik.values.resendButtonText}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.resendButtonText &&
            Boolean(formik.errors.resendButtonText)
          }
          helperText={
            formik.touched.resendButtonText && formik.errors.resendButtonText
          }
        />
      )}
      <InputField
        fullWidth
        id="redemptionLinkUrl"
        name="redemptionLinkUrl"
        label="Redemption Link Url"
        variant="standard"
        value={formik.values.redemptionLinkUrl}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={
          formik.touched.redemptionLinkUrl &&
          Boolean(formik.errors.redemptionLinkUrl)
        }
        helperText={
          formik.touched.redemptionLinkUrl && formik.errors.redemptionLinkUrl
        }
      />

      {formik.values.promotionType === PromotionType.NONCODE && (
        <RedemptionUrlTester
          type={TestType.STRETCH}
          cpnInputValue={formik.values.testCpnStretch}
          cpnInputOnChange={formik.handleChange}
          emailOrXcodeInputValue={formik.values.testXCODE}
          emailOrXcodeInputOnChange={formik.handleChange}
          testButtonOnClick={() =>
            testStretchRedemptionHandler(
              formik.values.redemptionLinkUrl,
              formik.values.testCpnStretch!,
              formik.values.testXCODE!
            )
          }
          testButtonDisabled={
            isLoadingTestRedemptionEmailMutation ||
            isLoadingGetRedemptionUrlMutation ||
            isTestUrlButtonDisabled(
              TestType.STRETCH,
              formik.values.redemptionMechanic,
              formik.values.slug,
              formik.values.redemptionLinkUrl,
              formik.values.testCpnStretch,
              "",
              formik.values.testXCODE
            )
          }
          testUrlErrorMsg={testStretchUrlErrorMessage}
        />
      )}
      {formik.values.promotionType !== PromotionType.DONATION && (
        <RedemptionUrlTester
          type={TestType.GFM}
          cpnInputValue={formik.values.testCpnGfm}
          cpnInputOnChange={formik.handleChange}
          emailOrXcodeInputValue={formik.values.testEmail}
          emailOrXcodeInputOnChange={formik.handleChange}
          testButtonOnClick={() =>
            testGFMRedemptionHandler(
              formik.values.redemptionMechanic,
              formik.values.testCpnGfm!,
              formik.values.redemptionLinkUrl,
              formik.values.testEmail!,
              formik.values.slug
            )
          }
          testButtonDisabled={
            isLoadingTestRedemptionEmailMutation ||
            isLoadingGetRedemptionUrlMutation ||
            isTestUrlButtonDisabled(
              TestType.GFM,
              formik.values.redemptionMechanic,
              formik.values.slug,
              formik.values.redemptionLinkUrl,
              formik.values.testCpnGfm,
              formik.values.testEmail
            )
          }
          testUrlErrorMsg={testGfmUrlErrorMessage}
        />
      )}

      {showRedemptionMechanic && (
        <>
          <StyledFormControl>
            <FormLabel id="redemptionMechanic-group">
              Redemption Mechanic
            </FormLabel>
            <RadioGroup
              aria-labelledby="redemptionMechanic-group"
              name="redemptionMechanic"
              value={formik.values.redemptionMechanic}
              onChange={(e) => {
                formik.handleChange(e);
                setTestGfmUrlErrorMessage("");
                setTestStretchUrlErrorMessage("");
              }}
            >
              {Object.values(OfferRedemptionMechanic)
                .filter(shouldIncludeMechanic)
                .map((mechanic) => (
                  <FormControlLabel
                    key={mechanic}
                    value={mechanic}
                    control={<Radio data-testid={mechanic} />}
                    label={mapMechanic.get(mechanic) as string}
                  />
                ))}
            </RadioGroup>
          </StyledFormControl>
          {formik.values.promotionType !== PromotionType.COMPETITION && (
            <StyledFormGroup>
              <FormLabel component="legend">
                Allow multiple redemptions
              </FormLabel>
              <Switch
                checked={formik.values.allowMultipleRedemption}
                name="allowMultipleRedemption"
                onChange={formik.handleChange}
              />
            </StyledFormGroup>
          )}
          {showRedemptionThreshold && (
            <InputField
              fullWidth
              id="redemptionThreshold"
              name="redemptionThreshold"
              label="Redemption Threshold"
              variant="standard"
              value={formik.values.redemptionThreshold}
              onChange={formik.handleChange}
              error={
                formik.touched.redemptionThreshold &&
                Boolean(formik.errors.redemptionThreshold)
              }
              helperText={
                formik.touched.redemptionThreshold &&
                formik.errors.redemptionThreshold
              }
            />
          )}
          {showMaximumRedemptions && (
            <InputField
              fullWidth
              id="maximumRedemptions"
              name="maximumRedemptions"
              label="Maximum Redemptions"
              variant="standard"
              value={formik.values.maximumRedemptions}
              onChange={formik.handleChange}
              error={
                formik.touched.maximumRedemptions &&
                Boolean(formik.errors.maximumRedemptions)
              }
              helperText={
                formik.touched.maximumRedemptions &&
                formik.errors.maximumRedemptions
              }
            />
          )}
        </>
      )}
      {showFeeOnRedemption && (
        <InputField
          fullWidth
          id="feeOnRedemption"
          name="feeOnRedemption"
          label="Fee on redemption"
          variant="standard"
          value={formik.values.feeOnRedemption}
          onChange={formik.handleChange}
          error={
            formik.touched.feeOnRedemption &&
            Boolean(formik.errors.feeOnRedemption)
          }
          helperText={
            formik.touched.feeOnRedemption && formik.errors.feeOnRedemption
          }
        />
      )}
      {showTransactionHistoryLabel && (
        <InputField
          fullWidth
          id="transactionHistoryLabel"
          name="transactionHistoryLabel"
          label="Transaction History Label"
          variant="standard"
          value={formik.values.transactionHistoryLabel}
          onChange={formik.handleChange}
          error={
            formik.touched.transactionHistoryLabel &&
            Boolean(formik.errors.transactionHistoryLabel)
          }
          helperText={
            formik.touched.transactionHistoryLabel &&
            formik.errors.transactionHistoryLabel
          }
        />
      )}
    </TabPanel>
  );
};

export default RedemptionSettingsPanel;
