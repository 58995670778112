import styled from "@emotion/styled";
import { CircularProgress, MenuItem } from "@mui/material";
import { AxiosError } from "axios";
import { FormikProps } from "formik";
import { useQuery } from "react-query";

import { listAttractions } from "../../../../clients/services/sams/promotion";
import { imageResizeMessages } from "../../../../constants/imageResizeMessages";
import { ErrorMessage } from "../../../ErrorMessage";
import ImageSelection from "../../../ImageSelection";
import SelectField from "../../../SelectField";
import TabPanel from "../../../TabPanel";
import { FormDataInterface } from "../../types";

interface Props {
  formik: FormikProps<FormDataInterface>;
  value: number;
}

const AttractionInfoPanel: React.FC<Props> = ({ formik, value }) => {
  const {
    isLoading,
    data: attractionData,
    error
  } = useQuery(["listAttractions"], () => listAttractions());

  if (isLoading) {
    return <CircularProgress data-testId={"loadingCircle"} />;
  }

  if (error) {
    return <ErrorMessage axiosErrors={[error as AxiosError]} />;
  }

  return (
    <TabPanel value={value} index={5}>
      <SelectField
        form={formik}
        label="Attraction name"
        labelID="AttractionId"
        name="attractionName"
      >
        {attractionData &&
          attractionData.map((attraction) => (
            <MenuItem key={attraction.id} value={attraction.attractionName}>
              {attraction.attractionName}
            </MenuItem>
          ))}
      </SelectField>
      <ImageSelection
        id="attractionImage"
        value={formik.values.attractionImage!}
        imageResizeMessage={imageResizeMessages.attractionImage}
        error={
          formik.touched.attractionImage &&
          Boolean(formik.errors.attractionImage)
        }
        helperText={
          formik.touched.attractionImage && formik.errors.attractionImage
        }
        btnText={"Select attraction image"}
        setFieldValue={formik.setFieldValue}
        isImageSelectionDisabled={false}
        isImageSelected={
          !!formik.values.attractionImage &&
          formik.values.attractionImage.length !== 0
        }
        postType="attraction"
        imageType="attraction"
        imageToUpload={formik.values.attractionImage}
        postHeadline={formik.values.code}
        setImageFieldsValues={(data: any) => {
          if ("attractionImage" in data) {
            formik.setFieldValue("attractionImage", data.attractionImage);
          }
        }}
      />
    </TabPanel>
  );
};

export default AttractionInfoPanel;
