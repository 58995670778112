import { useEffect, useState } from "react";
import { useParams } from "react-router";

import { CreateMorePageRequestBody } from "../../clients/services/sams/content/morePage";
import { MorePageType } from "../../clients/services/sams/types";

import { FormModelTransformer } from "./types";

interface IParams {
  id?: string;
}

const useFormData = (
  transformer: FormModelTransformer,
  getDataFunction: any,
  createFunction: any,
  updateFunction: any,
  isUpdate = false
) => {
  const [result, setResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const params: IParams = useParams();
  const isUpdateOperation = isUpdate || Boolean(params?.id);

  const mutationFunction = (data: CreateMorePageRequestBody) => {
    const formData = { ...data };
    if (formData.type === MorePageType.INTERNAL_PAGE) {
      formData.url = "";
    } else {
      formData.image = "";
      formData.pageTitle = "";
      formData.content = "";
    }

    const mutation = isUpdateOperation ? updateFunction : createFunction;

    return mutation(formData);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (isUpdateOperation) {
        setIsLoading(true);
        try {
          const data = await getDataFunction(params.id);
          setResult(data);
          setIsLoading(false);
        } catch {
          setIsError(true);
        }
      }
    };
    fetchData();
  }, []);

  const formData =
    isUpdateOperation && result !== null
      ? transformer.modelToFormData(result)
      : transformer.getFormDataTemplate();

  return [formData, mutationFunction, isLoading, isError, isUpdateOperation];
};

export default useFormData;
